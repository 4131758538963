import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    MailFilled,
    TagsFilled,
    TabletOutlined,
    HistoryOutlined,
    SettingFilled,
    DashboardFilled
  } from '@ant-design/icons';

import UserLogin from "../pages/userLogin";
import Residents from "../pages/resident";
import CaptchaCode from "../pages/captcha";
import Page404 from "../pages/pageNotFound";
import UiPythonScript from "../pages/uips";
import UserLogout from "../pages/userLogout";
import Index from '../pages';
import Welcome from '../pages/welcome';
import AppResident from '../pages/resident1';
import MailNotice from '../pages/mail';
import CronSetting from '../pages/cron';
import CronRecords from '../pages/cronRecords';


export const mainRoutes = [
    {
        path: "/login",
        component: UserLogin
    },
    {
        path: "/404",
        component: Page404
    },
    {
        path: "/",
        component: Index
    }
];

export const adminRoutes = [
    {
        path: "/admin/index",
        component: Welcome,
        exact: true,
        isShow: true,
        title: "首页",
        icon: <DashboardFilled></DashboardFilled>
    },
    {
        path: "/admin/crontab/setting",
        component: CronSetting,
        exact: true,
        isShow: true,
        title: "定时任务设置（建设中）",
        icon: <SettingFilled></SettingFilled>
    },
    {
        path: "/admin/crontab/records",
        component: CronRecords,
        exact: true,
        isShow: true,
        title: "定时任务执行纪录（建设中）",
        icon: <HistoryOutlined></HistoryOutlined>
    },
    {
        path: "/admin/mail",
        component: MailNotice,
        exact: true,
        isShow: false,
        title: "邮件",
        icon: <MailFilled></MailFilled>
    },
    {
        path: "/admin/newResident",
        component: AppResident,
        exact: true,
        isShow: true,
        title: "虚拟人员列表-新",
        icon: <TeamOutlined></TeamOutlined>
    },
    {
        path: "/admin/resident",
        component: Residents,
        exact: true,
        isShow: true,
        title: "虚拟人员信息",
        icon: <TeamOutlined></TeamOutlined>
    },
    {
        path: "/admin/captcha",
        component: CaptchaCode,
        isShow: true,
        title: "验证码识别",
        icon: <PieChartOutlined></PieChartOutlined>
    },
    {
        path: "/admin/uip",
        component: UiPythonScript,
        isShow: true,
        title: "UI脚本生成",
        icon: <FileOutlined></FileOutlined>
    },
    {
        path: "/admin/logout",
        component: UserLogout,
        isShow: true,
        title: "注销用户",
        icon: <DesktopOutlined></DesktopOutlined>
    }
]

