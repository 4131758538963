import { InputNumber, Col, Select, Row, Table } from 'antd';
import React from 'react';
import { Button, Form, Input } from 'antd-mobile'
import instance from '../core/lib/axiosCore';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    filters: [
    //   {
    //     text: 'Joe',
    //     value: 'Joe',
    //   },
    //   {
    //     text: 'Jim',
    //     value: 'Jim',
    //   },
    //   {
    //     text: 'Submenu',
    //     value: 'Submenu',
    //     children: [
    //       {
    //         text: 'Green',
    //         value: 'Green',
    //       },
    //       {
    //         text: 'Black',
    //         value: 'Black',
    //       },
    //     ],
    //   },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: '性别',
    dataIndex: 'gender',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: '身份证号',
    dataIndex: 'id_card',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: '生日',
    dataIndex: 'birthday',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: '地址',
    dataIndex: 'address',
    filters: [
    //   {
    //     text: 'London',
    //     value: 'London',
    //   },
    //   {
    //     text: 'New York',
    //     value: 'New York',
    //   },
    ],
    onFilter: (value, record) => record.address.indexOf(value) === 0,
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

class Residents extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: []
        }
    };

    componentDidMount (num=10, gender=null, min_age=6, max_age=12) {
        instance.get("api/resident", {params: {
            num: num,
            gender: gender,
            min_age: min_age,
            max_age: max_age
        }}).then(res=>{
            let d = res.data.data;
            if (d instanceof Array) {
                d.forEach(_d => {
                    _d.key=_d.id_card
                });
                this.setState({
                    data: d,
                    msg: ''
                })
                console.log(this.state);
            } else {
                this.setState({
                    msg: '请求错误：' + res.data.message
                })
            }
            
        })
    };

    handleSubmit = (values) => {
        this.componentDidMount(values.num, values.gender, values.min_age, values.max_age)
    };

    render() {
        let res = this.state.data
        return (
            <div>
                虚拟数据，如有雷同，纯属巧合。
            <Form
                name="basic"
                initialValues={{ num: 10, gender: null, min_age: 6, max_age: 12 }}
                onFinish={this.handleSubmit}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
                
            >
                <Row>
                <Col>
                    <Form.Item
                        label="数量"
                        name="num"
                        rules={[{ required: true, message: '请输入生成的数据量!' }]}
                    >
                        <InputNumber min={1} max={500} precision={0} />
                    </Form.Item>

                    <Form.Item
                    label="性别"
                    name="gender"
                >
                    <Select style={{width: 120,}} options={[
                        {
                            label: "请选择",
                            value: null
                        },
                        {
                            label: "男",
                            value: "m"
                        },
                        {
                            label: "女",
                            value: "f"
                        },
                    ]} />
                </Form.Item>

                
                </Col>

                </Row>
                
                
                <Form.Item
                    label="最小年龄"
                    name="min_age"
                >
                    <InputNumber min={3} max={100} precision={0} />
                </Form.Item>

                <Form.Item
                    label="最大年龄"
                    name="max_age"
                >
                    <InputNumber min={3} max={100} precision={0} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                    offset: 8,
                    span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                    获取更多数据
                    </Button>
                    <div color='red'>{this.state.msg}</div>
                </Form.Item>
            </Form>
            <Table columns={columns} dataSource={res} onChange={onChange} />
            </div>  
        )
    }
}

export default Residents;
