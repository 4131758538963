import React from "react";
import Nav from './components/navigation';
import { BrowserRouter, Route, Link ,Switch, Redirect} from 'react-router-dom';
import { Breadcrumb, Layout, Menu } from 'antd';
import { useState } from 'react';
import { adminRoutes } from "./routes";

const { Header, Content, Footer, Sider } = Layout;

function App() {
  return (
    <Nav className="App">
      <Switch> 
        {adminRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={routeProps => {
                return <route.component {...routeProps} />;
              }}
            />
          );
        })}
        <Redirect to="/404"></Redirect>
      </Switch>
    </Nav>
  );
}

export default App;

