import instance from '../core/lib/axiosCore';
import { Button, Form } from 'antd';
import React from 'react';
import { Input } from 'antd-mobile';


class CaptchaCode extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: []
        }
    };

    componentDidMount = (values={}) => {
        if (JSON.stringify(values)!=='{}') {
            instance.get("api/tools/b64/code", {params: {
                b64_data: values.b64_data
            }}).then(res=>{
                this.setState({
                    code: res.data.data,
                    message: res.data.message
                })
            })
        } else {
            console.log("初始化", JSON.stringify(values))
        };
        
    };

    handleSubmit = (values) => {
        console.log(values);
        this.componentDidMount(values)
    }

    render() {
        return (
            <div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={this.handleSubmit}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
            >
        
                <Form.Item
                    label="base64数据"
                    name="b64_data"
                    rules={[{ required: true, message: 'captcha base64 数据！' }]}
                >
                    <Input />
                </Form.Item>
        
                <Form.Item
                    wrapperCol={{
                    offset: 8,
                    span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                    解析code
                    </Button>
                    <div>解析结果：
                        <p>{this.state.message}</p>
                        <p>{this.state.code}</p>
                    </div>
                </Form.Item>
              </Form>
            </div>
          );
        };
    }



export default CaptchaCode;