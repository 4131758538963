import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Table } from 'antd';
import React, { useEffect } from 'react';
import  useState  from 'react-usestateref';
import instance from '../core/lib/axiosCore';

const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      filters: [
      //   {
      //     text: 'Joe',
      //     value: 'Joe',
      //   },
      //   {
      //     text: 'Jim',
      //     value: 'Jim',
      //   },
      //   {
      //     text: 'Submenu',
      //     value: 'Submenu',
      //     children: [
      //       {
      //         text: 'Green',
      //         value: 'Green',
      //       },
      //       {
      //         text: 'Black',
      //         value: 'Black',
      //       },
      //     ],
      //   },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: '性别',
      dataIndex: 'gender',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '身份证号',
      dataIndex: 'id_card',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '地址',
      dataIndex: 'address',
      filters: [
      //   {
      //     text: 'London',
      //     value: 'London',
      //   },
      //   {
      //     text: 'New York',
      //     value: 'New York',
      //   },
      ],
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
  ];

const onChange = (pagination, filters, sorter, extra) => {
console.log('params', pagination, filters, sorter, extra);
};

const { Option } = Select;
const AdvancedSearchForm = () => {
  const [expand, setExpand] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    doFinsh({"num": 10, "min_age":6, "max_age":12})
    }, [result]);
  var [result, setResult, resultRef] = useState([]);
  const getFields = () => {
    const count = expand ? 10 : 6;
    const children = [];
    const fields = [
        {"label": "数量", "name": "num"},
        {"label": "性别", "name": "gender"},
        {"label": "最小年龄", "name": "min_age"},
        {"label": "最大年龄", "name": "max_age"}
    ]
    fields.forEach((elem, index) => {
      children.push(
        <Col span={8} key={elem.label}>
          <Form.Item
            name= {elem.name}
            label={elem.label}
            rules={[
              {
                required: false,
                message: `请输入${elem.name}`,
              },
            ]}
          >
            {elem.label !== "性别" ? (
              <InputNumber placeholder={elem.label} min={1} max={500} precision={0}/>
            ) : (
              <Select>
                <Option value={null}>请选择</Option>
                <Option value="m">男</Option>
                <Option value="f">女
                 
                </Option>
              </Select>
            )}
          </Form.Item>
        </Col>,
      );
    })
    return children;
  };

  const doFinsh = (values) => {
    instance.get("api/resident", {params: {
        num: values.num ? values.num : 10,
        gender: values.gender ? values.gender : null,
        min_age: values.min_age ? values.min_age : null,
        max_age: values.max_age ? values.max_age : null
    }}).then(res=>{
        let d = res.data.data;
        if (d instanceof Array) {
            d.forEach(_d => {
                _d.key=_d.id_card
            });
            setResult({
                data: d,
                msg: ''
            });
        } else {
            setResult({
                msg: '请求错误：' + res.data.message
            })
        }
        
    })
};
  const onFinish = (values) => {
    doFinsh(values)
  };
  return (
    <div> 虚拟数据，如有雷同，纯属巧合。
      <Form
        form={form}
        initialValues={{ num: 10, gender: null, min_age: 6, max_age: 12 }}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            获取数据
          </Button>
          {/* <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button> */}
          {/* <a
            style={{
              fontSize: 12,
            }}
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? <UpOutlined /> : <DownOutlined />} Collapse
          </a> */}
        </Col>
      </Row>
    </Form>
    <Table columns={columns} dataSource={resultRef.current.data} onChange={onChange} />
    </div>
    
  );
};
const AppResident = () => (
  <div>
    <AdvancedSearchForm />
  </div>
);
export default AppResident;