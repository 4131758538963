import { useEffect } from "react";
import cookie from 'react-cookies';
import { useState } from "react";

const Index = () => {

    useEffect(() => {
        if (!cookie.load('token')) {
            window.location.href = '/login'
        } else
        {
            window.location.href = '/admin/index'
        }
      }, [])

    return (
        <div></div>
    )
}
export default Index;