import instance from '../core/lib/axiosCore';


import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import React, { useState } from 'react';

let ret = ""
const UiPythonScript = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setUploading(true);
    // You can use any AJAX library you like
    instance.post('api/ui/div/case', formData
    )
      .then((res) => ret=res.data.data)
      .then(() => {
        // setFileList([]);
        console.log(ret)
        message.success('upload successfully.');
      })
      .catch((err) => {
        console.log(err)
        message.error('upload failed.');
        console.log("fr", ret)
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const getTemplate = () => {
        window.open("https://tools.zrirver.com/static/templates/div_case_template.xlsx", "_blank")
  
    }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>选取文件（当前仅支持单个文件上传后的脚本）</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || fileList.length>=2}
        loading={uploading}
        style={{
          marginTop: 16,
        }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
      <Button type="primary" htmlType="submit" onClick={getTemplate}>
                获取模版
      </Button>
      <div>
        <pre>
            <code>{ret}</code>
        </pre>

      </div>
      
    </>
  );
};
export default UiPythonScript;

// export default class UiPythonScript extends React.Component{
//     constructor(props){
//         super(props)
//         this.state = {
//             data: []
//         };
//     };

//     handleSubmit (values) {
//         let formData = new FormData
//         formData.append("file", values.file)
//         instance.post("api/ui/div/case", formData).then(res=>{
//             console.log("ddddd", res)
//             let d = res.data.data;
//             this.setState({
//                 data: d,
//                 msg: ''
//             })
//             console.log(this.state);
//         });
        
//     };

//     getTemplate = () => {
//         window.open("http://192.168.2.250:8080/static/templates/div_case_template.xlsx", "_blank")
  
//     }

//     render() {
        
//         console.log('eeeee', this.state)
//         let res = this.state.data
//         console.log("rrrrr", res)
//         return (
//             <div>
//                 <Form
//                 name="basic"
//                 initialValues={{ remember: true }}
//                 onFinish={this.handleSubmit}
//                 onFinishFailed={this.onFinishFailed}
//                 autoComplete="off"
//             >

//                 <Form.Item
//                     label="文件"
//                     name="file"
//                     type = "file"
//                     rules={[{ required: true, message: '上传文件!' }]}
//                 >
//                     <Input type="file"/>
//                 </Form.Item>

//                 <Form.Item
//                     wrapperCol={{
//                     offset: 8,
//                     span: 16,
//                     }}
//                 >
//                     <Button type="primary" htmlType="submit">
//                     获取脚本数据
//                     </Button>
//                     <div color='red'>{this.state.msg}</div>
//                 </Form.Item>
//             </Form>
//                 <Button type="primary" htmlType="submit" onClick={this.getTemplate}>
//                 获取模版
//                 </Button>
//                 <div color='red'>{this.state.msg}</div>
//             </div>  
//         )
//     }
// }