import md5 from 'js-md5';
import instance from '../core/lib/axiosCore';
import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import cookie from 'react-cookies';
import useState from 'react-usestateref';
import { useEffect } from 'react';

const MailNotice = () => {

  const [msg, setMsg, msgRef] = useState('')

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleSubmit = (values) => {
    const payload = {
        "subject": values.subject,
        "content": values.content
    };
    
    instance.post("api/mail/notice", payload).then(res => {
        if (res.data.code === 0) {
            setMsg("已成功发送邮件")
        } else {
            setMsg(res.data.message)
        }
    })

}
  return (
    <div>
    <Form
      name="loginForm"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 10,
      }}
      initialValues={{
        account: "",
        remember: false,
      }}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"

    >
      <Form.Item
        label="邮件标题"
        name="subject"
        rules={[
          {
            required: true,
            message: '请输入邮件标题!',
          },
        ]}
      >
        <Input maxLength='50' placeholder='邮件标题（长度不超过50）'/>
      </Form.Item>

      <Form.Item
        label="邮件内容"
        name="content"
        rules={[
          {
            required: true,
            message: '请输入邮件内容！',
          },
        ]}
      >
        <Input.TextArea maxLength='100' placeholder='邮件内容（长度不超过100字符）'/>
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >

      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          发送邮件
        </Button>
        <p style={{color:'red'}}>{msgRef.current}</p>
      </Form.Item>
    </Form>
    </div>
    
  );
};
export default MailNotice;