import md5 from 'js-md5';
import instance from '../core/lib/axiosCore';
import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import cookie from 'react-cookies';
import { useState } from 'react';
import { useEffect } from 'react';

const UserLogin = () => {

  const [msg, setMsg] = useState('')
  useEffect(() => {
    if (cookie.load('token')) {
        window.location.href = 'admin/index'
    }
  }, [])
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleSubmit = (values) => {
    const payload = {
        "account": values.account,
        "password": md5(values.password)
    };
    
    instance.post("api/user/login", payload).then(res => {
        if (res.data.code === 0) {
            cookie.save('token', res.data.data.token,{path: '/'})
            window.location.href = '/admin/index'
        } else {
            setMsg(res.data.message)
        }
    })

}
  return (
    <div>
    <Form
      name="loginForm"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 10,
      }}
      initialValues={{
        account: "river",
        remember: false,
      }}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"

    >
      <Form.Item
        label="账户"
        name="account"
        rules={[
          {
            required: true,
            message: '请输入姓名!',
            maxLength: 20
          },
        ]}
      >
        <Input maxLength='20'/>
      </Form.Item>

      <Form.Item
        label="密码"
        name="password"
        rules={[
          {
            required: true,
            message: '请输入密码！',
            maxLength: 20
          },
        ]}
      >
        <Input.Password maxLength='20'/>
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >

      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          登录
        </Button>
        <p style={{color:'red'}}>{msg}</p>
      </Form.Item>
    </Form>
    </div>
    
  );
};
export default UserLogin;