import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import { BrowserRouter, Route, Link ,Switch, Router, Redirect} from 'react-router-dom';
import { mainRoutes } from './routes';



ReactDOM.render(
  <BrowserRouter>
    <Switch>
        <Route path="/admin" render={routeProps => <App {...routeProps} />}/>
        {mainRoutes.map(route => {
            return <Route key={route.path} {...route} />;
        })}
        <Redirect to="/404"></Redirect>
    </Switch>
  </BrowserRouter>,
    document.getElementById('root'));
