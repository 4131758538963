import axios from "axios";
import cookie from 'react-cookies';

// const BASE_URL = process.env.BASE_URL

const instance = axios.create({
    timeout: 60000,
    // baseURL: "http://192.168.2.250:8080/"
    baseURL: "https://tools.zrirver.com/"
});

instance.interceptors.request.use(
    config => {
        const token = cookie.load('token');
        if (token) {
            config.headers['Authorization'] = token;
        } else {
            if (window.location.pathname === '/login') {
                console.log("当前路径在登录页")
            } else {
                window.location.href = '/login'
            } 
        }
        config.headers['SAFE-CODE'] = "riw";
        return config;
    }
)

export default instance;